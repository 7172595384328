<template>
  <div>
    <div
      class="intro-container row-24 m-0 justify-center items-center"
      @click="scrollDown"
    >
      <h1 class="intro-title text-white col-18" v-html="payload.introtext" />
      <ScrollIndicator />
    </div>
    <section class="personen-section row-24 m-0">
      <div
        class="personen-container flex flex-wrap row-24 col-22 offset-1
        sm:col-16 sm:offset-4 md:offset-5 lg:offset-4 m-0"
      >
        <div
          v-for="person in personen"
          :key="person.id"
          :to="person.link"
          class="personen-card col-24 sm:col-12 md:col-12 lg:col-8 m-0 flex flex-col justify-center items-center"
          @mousemove="getMousePosition"
        >
          <h2 v-html="person.name" />
          <h3
            v-html="person.funktion"
          />
          <div class="person-portrait" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
            <img
              :src="person.portrait.normal"
              :alt="person.alt"
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      personen: this.payload.personen,
      mouseX: 0,
      mouseY: 0,
    };
  },
  methods: {
    getMousePosition(event) {
      const card = event.target.getBoundingClientRect();
      const x = event.clientX - card.left;
      const y = event.clientY - card.top;
      this.mouseX = x;
      this.mouseY = y;
    },
    scrollDown() {
      window.scroll({
        top: window.innerHeight,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import "@/assets/styles/_mixin.scss";

.intro-container {
  .intro-title :deep(p) {
    font-size: 20px;
    @screen sm {
      font-size: 32px;
    }
    @screen md {
      font-size: 36px;
    }
    @screen lg {
      font-size: 48px;
    }
  }
}

.personen {
  &-section {
    background-color: var(--color-black);
    padding-top: 0;
    @screen lg {
      padding-top: 8rem;
    }
  }
  &-container {
    padding: 4rem 0;
  }
}
.personen-card {
  position: relative;
  padding: 50px 0;
  cursor: crosshair;
  @screen md {
    padding: 75px 0;
  }
  &:hover {
    .person-portrait {
      opacity: 1;
    }
    h2, h3 {
      z-index: 5;
    }
  }
  h2 {
    pointer-events: none;
    :deep(p) {
      font-family: var(--font-primary);
      color: var(--color-white);
      text-align: center;
      margin: 0 0 0.5rem 0;
      z-index: 5;
      font-size: 1.75rem;
      @screen md {
        font-size: 2rem;
      }
      @screen lg {
        font-size: 2.5rem;
      }
    }
  }
  h3 {
    pointer-events: none;
    z-index: 5;
    color: var(--color-white);
    margin: 0;
    font-size: px(14);
    text-transform: uppercase;
    @screen md {
      font-size: 1.25rem;
    }
  }

  .person-portrait {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    min-height: px(200);
    opacity: 0;
    @screen md {
      min-height: px(300);
    }
    @screen lg {
      min-height: px(350);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      margin: 0;
    }
  }
}
</style>
